const actions = [
  {
    title: 'My profile',
    route: '/u/profile',
    icon: 'bi-person-circle',
  },
  {
    title: 'My password',
    route: '/u/password',
    icon: 'bi-lock-fill',
  },
];

const profile = [
  {
    type: 'text',
    name: 'first_name',
    label: 'Firstname',
    placeholder: 'Your firstname',
    component: 'el-input',
    required: true,
    pattern:/^[a-zA-Z0-9._-\s]+$/,
    errorMessage: 'Your firstname is not valid',
  },
  {
    type: 'text',
    name: 'last_name',
    label: 'Last name',
    placeholder: 'Your last name',
    component: 'el-input',
    required: true,
    pattern: /^[a-zA-Z0-9._-\s]+$/,
    errorMessage: 'Your lastname is not valid'
  },
  {
    type: 'text',
    name: 'username',
    label: "Display name",
    placeholder: "Your display name",
    component: 'el-input',
    required: true,
    pattern: /^[a-zA-Z0-9._-\s]+$/,
    errorMessage: 'Your display name is not valid',
  },
  {
    type: 'text',
    name: 'email',
    label: 'Email address',
    placeholder: 'Your email address',
    component: 'el-input',
    required: true,
    pattern: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
    errorMessage: 'Your email address is not valid',
  },
  {
    type: 'text',
    name: 'company',
    label: 'Company',
    placeholder: 'Your company name',
    required: false,
    component: 'el-input',
    pattern: /^[a-zA-Z0-9._-\s]+$/,
    errorMessage: 'Your company name is not valid',
  },
]

export { actions, profile };
